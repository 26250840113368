// extracted by mini-css-extract-plugin
export var pressCard__date = "ck_pT";
export var pressCard__description = "ck_pS";
export var pressCard__image_large = "ck_pK";
export var pressCard__image_small = "ck_pL";
export var pressCard__info = "ck_pM";
export var pressCard__info_large = "ck_pN";
export var pressCard__info_small = "ck_pP";
export var pressCard__large = "ck_pV";
export var pressCard__link = "ck_pJ";
export var pressCard__post = "ck_pH";
export var pressCard__small = "ck_pG";
export var pressCard__source = "ck_pQ";
export var pressCard__title = "ck_pR";